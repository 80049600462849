(function () {
    'use strict';

    angular
            .module('admin')
            .service('EpisodeTemplatesService', EpisodeTemplatesService);

    EpisodeTemplatesService.$inject = ['$q', 'RestResources'];

    function EpisodeTemplatesService ($q, RestResources) {
        var service = {
            get: get,
            getAll: getAll,
            save: save,
            update: update,
            remove: remove
        };

        return service;

        function get (campaignId, episodeTemplateId) {
            var deferred = $q.defer();
            var resource = RestResources.getIhhEpisodeTemplatesResources();

            resource.get({
                campaignId: campaignId,
                episodeTemplateId: episodeTemplateId
            }, function (result) {
                deferred.resolve(result);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function getAll (campaignId) {
            var deferred = $q.defer();
            var resource = RestResources.getIhhEpisodeTemplatesResources();

            resource.query({
                campaignId: campaignId
            }, function (result) {
                deferred.resolve(result);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function update (campaignId, episodeTemplateId, episodeTemplate) {
            var deferred = $q.defer();
            var resource = RestResources.getIhhEpisodeTemplatesResources();

            resource.update({
                campaignId: campaignId,
                episodeTemplateId: episodeTemplateId
            }, episodeTemplate, function (result) {
                deferred.resolve(result);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function save (campaignId, episodeTemplate) {
            var deferred = $q.defer();
            var resource = RestResources.getIhhEpisodeTemplatesResources();

            resource.save({
                campaignId: campaignId
            }, episodeTemplate, function (result) {
                deferred.resolve(result);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }

        function remove (campaignId, episodeTemplateId) {
            var deferred = $q.defer();
            var resource = RestResources.getIhhEpisodeTemplatesResources(campaignId, episodeTemplateId);

            resource.delete({}, function (result) {
                deferred.resolve(result);
            }, function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }
    }
})();