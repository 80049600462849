(function() {
    'use strict';

    /**
     * This service is responsible for the configuration object of alert resource materials
     *
     * @ngdoc service
     * @memberOf admin
     * @name AlertResourceMaterialsConfigService
     * @description
     * service for alert resource material config
     *
     * @param RestResources
     * @param NextPageSvc
     * @param ItemChooserSvc
     * @param ResourceMaterialsConstants
     * @param ItemChooserConstants
     * @param TagsSvc
     */
    angular
        .module('admin')
        .service('AlertResourceMaterialsConfigService', AlertResourceMaterialsConfigService);

    AlertResourceMaterialsConfigService.$inject = ['TagsSvc', 'RestResources', 'ItemChooserSvc', 'ResourceMaterialsConstants', 'ResourceMaterialsFactory', 'OpsTherapyObjectSvc'];

    function AlertResourceMaterialsConfigService(TagsSvc, RestResources, ItemChooserSvc, ResourceMaterialsConstants, ResourceMaterialsFactory, OpsTherapyObjectSvc) {
        var SAVE_STATE = 'ihh.chooser-state.resource-materials';
        var ERR_TITLE = 'Could not load the resource materials list';

        var config = ItemChooserSvc.createConfig(SAVE_STATE, RestResources.getResourceMaterialsResource(), ERR_TITLE);
        OpsTherapyObjectSvc.setupTherapyObjectPicker(config, ResourceMaterialsConstants.resources.name.singular, ResourceMaterialsConstants.resources.name.plural, true, false);

        config.setExtraQueryParams({complete: true});
        config.setCustomTemplate('/app/alerts/outgoing/alert-resource-materials.tpl.html');

        config.setProcessItemFn(function (item) {
            item.thumbnailUrl = RestResources.getResourceMaterialThumbnailUrl(item);
            item.typeLabel = ResourceMaterialsFactory.getTypeLabel(item);

            return item;
        });

        this.refreshTags = function() {
            config.clearFilterOptions();
            config.addFilterOption('resourceCategory', ResourceMaterialsConstants.resources.category, 'value', 'id', 'tags', 'value', TagsSvc.getTags(ResourceMaterialsConstants.resources.tags));
        };

        this.init = function(selectedFn) {
            config.setSelectedFn(selectedFn);

            this.refreshTags();
            config.recallState();

            if (config.getDirty()) {
                config.nextPage();
            }

            return config;
        };
    }
})();
